import Vue from 'vue'
import Router from 'vue-router'
// import CONFIG from '../src/config/settings'
// const BASE_URL = CONFIG.BASEURL

Vue.use(Router)

export default new Router({
  mode: 'history',
  // base: BASE_URL,
  routes: [
    {
      path: '/', component: () => import('./views/View.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/basic/Land.vue')
        },
        {
          path: '/privacy-notice',
          name: 'privacy',
          component: () => import('./views/basic/Privacy.vue')
        },
        {
          path: '/terms',
          name: 'terms',
          component: () => import('./views/basic/Terms.vue')
        },
        {
          path: '/cookies-policy',
          name: 'cookies',
          component: () => import('./views/basic/Cookies.vue')
        },
        {
          path: '/aml-kyc-policy',
          name: 'kyc',
          component: () => import('./views/basic/Kyc.vue')
        },
        {
          path: '/trading',
          name: 'trading',
          component: () => import('./views/Trading.vue')
        },
        {
          path: '/signin',
          name: 'signin',
          component: () => import('./views/SignIn.vue')
        },
        {
          path: '/signup',
          name: 'signup',
          component: () => import('./views/SignUp.vue')
        },
        {
          path: '/reset-password',
          name: 'reset',
          component: () => import('./views/ResetPass.vue')
        },
        {
          path: '/profile/wallet',
          name: 'wallet',
          component: () => import('./views/profile/Wallet.vue')
        },
        {
          path: '/profile/settings',
          name: 'settings',
          component: () => import('./views/profile/Settings.vue')
        },
        // {
        //   path: '/profile/verification',
        //   name: 'verification',
        //   component: () => import('./views/profile/Verification.vue')
        // },
      ]
    },
    // {
    //   path: '/', component: () => import('./views/basic/View.vue'),
    //   children: [
    //   ]
    // }
  ],
  linkExactActiveClass: 'active'
})
