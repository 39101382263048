import Vue from 'vue';
import Vuex from 'vuex';


import { auth } from './auth.module'
import { balance } from './balance.module'
import { data } from './data.module'
import { basic } from './basic.module'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    basic,
    auth,
    balance,
    data,
  }
});