import ENUM from "../config/enums"

export const basic = {
    namespaced: true,
    state: {
        loading: ENUM.LOADING,
        preload: {
            trading: ENUM.LOADING,
        },
        params: {
            auth: false,
            data: false,
            balance: false,
            info: false,
            chart: 0,
            order: false,
            orders: false,
            assets: false,
        }
    },
    actions: {
        trading({ commit }) {
            commit('trading', ENUM.LOADING)
        },
        load({ commit }) {
            commit('load', ENUM.LOADING)
        },
        data({ commit, dispatch }, value) {
            commit('data', value)
            dispatch('load')
        },
        auth({ commit, dispatch }, value) {
            commit('auth', value)
            dispatch('load')
        },
        balance({ commit, dispatch }, value) {
            commit('balance', value)
            dispatch('load')
        },
        info({ commit, dispatch }, value) {
            commit('info', value)
            dispatch('trading')
        },
        chart({ commit }, value) {
            commit('chart', value)
        },
        order({ commit, dispatch }, value) {
            commit('order', value)
            dispatch('trading')
        },
        orders({ commit, dispatch }, value) {
            commit('orders', value)
            dispatch('trading')
        },
        assets({ commit, dispatch }, value) {
            commit('assets', value)
            dispatch('trading')
        }
    },
    mutations: {
        load(state) {
            if(state.params.auth && state.params.balance && state.params.data) {
                setTimeout(() => {
                    state.loading = ENUM.LOADED
                }, 200)
            } else {
                state.loading = ENUM.LOADING
            }
        },
        trading(state) {
            if(state.params.info && state.params.order) {
                setTimeout(() => {
                    state.preload.trading = ENUM.LOADED
                }, 200)
            } else {
                state.preload.trading = ENUM.LOADING
            }
        },
        data(state, value) {
            state.params.data = value
        },
        auth(state, value) {
            state.params.auth = value
        },
        balance(state, value) {
            state.params.balance = value
        },
        info(state, value) {
            state.params.info = value
        },
        chart(state, value) {
            state.params.chart = value
        },
        order(state, value) {
            state.params.order = value
        },
        orders(state, value) {
            state.params.orders = value
        },
        assets(state, value) {
            state.params.assets = value
        }
    },
}
