import PaymentService from '../services/payment.service'

const token = JSON.parse(localStorage.getItem('token'))

export const balance = {
    namespaced: true,
    state: {
        balance: null,
        orders: null,
        transactions: null,
    },
    actions: {
        balance({ commit, dispatch }, data) {
            commit('balanceUpdate', data)
            dispatch('basic/balance', true, { root: true })
        },
        orders({ commit }, data) {
            commit('ordersUpdate', data)
        },
        transactions({ commit }) {
            return PaymentService.getTransactions(token).then(
                data => {
                    commit('transactionsUpdate', data)
                    return Promise.resolve(data)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        },
        withdraw({ commit }, params) {
            return PaymentService.sendWithdraw(token, params).then(
                data => {
                    commit('balance', data)
                    return Promise.resolve(data)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        }
    },
    mutations: {
        balanceUpdate(state, data) {
            state.balance = data
        },
        ordersUpdate(state, data) {
            state.orders = data
        },
        transactionsUpdate(state, data) {
            state.transactions = data
        }
    }
}
