import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import Fragment from 'vue-fragment'
import VueNumerals from 'vue-numerals'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VeeValidate from 'vee-validate'
import VueSimpleAlert from "vue-simple-alert"

Vue.use(VueSimpleAlert)

Vue.config.productionTip = false

Vue.use(VeeValidate)

Vue.use(VueNumerals)

Vue.use(Fragment.Plugin)

import CONFIG from '../src/config/settings'
const API_URL = CONFIG.SOCKET

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(API_URL),
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'socket_'
    }
  })
)

const toast_options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}

Vue.use(Toast, toast_options)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
