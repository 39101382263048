import axios from 'axios'
import CONFIG from '../config/settings'
const API_URL = CONFIG.APIURL

class AuthService {
    profile(token) {
        return axios
            .post(API_URL + 'auth/profile', {
                id: token
            })
            .then(response => {
                return response.data
            })
    }
    login(user) {
        return axios
            .post(API_URL + 'auth/signin', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data) localStorage.setItem('token', JSON.stringify(response.data))
                return response.data
            })
    }
    logout() {
        return localStorage.removeItem('token')
    }
    register(user) {
        return axios
            .post(API_URL + 'auth/signup', {
                username: user.username,
                email: user.email,
                password: user.password,
                phone: user.phone,
                code: user.code
            })
            .then(response => {
                if (response.data) localStorage.setItem('token', JSON.stringify(response.data))
                return response.data
            })
    }
    updatePassword(token, user) {
        return axios
            .post(API_URL + 'auth/update/password', {
                id: token,
                password: {
                    old: user.password.old,
                    new: user.password.new
                }
            })
            .then(response => {
                return response.data
            })
    }
    updateProfile(token, user) {
        return axios
            .post(API_URL + 'auth/update/personal', {
                id: token,
                user: {
                    name: user.name,
                    dob: user.dob,
                    address: {
                        present: user.address.present,
                        permanent: user.address.permanent,
                        city: user.address.city,
                        country: user.address.country,
                        zip: user.address.zip,
                    }
                }
            })
            .then(response => {
                return response.data
            })
    }
    verify(token, user) {
        return axios
            .post(API_URL + 'auth/update/verification', {
                id: token,
                user: {
                    first: user.first,
                    last: user.last,
                    country: user.country,
                    address: user.address,
                    phone: user.phone,
                    dob: user.dob,
                    document: user.document,
                    number: user.number,
                    photo: {
                        document: {
                            name: user.photo.document.name,
                            src: user.photo.document.src
                        },
                        person: {
                            name: user.photo.person.name,
                            src: user.photo.person.src
                        }
                    }
                }
            })
            .then(response => {
                return response.data
            })
    }
}

export default new AuthService();