import AuthService from '../services/auth.service'
const token = JSON.parse(localStorage.getItem('token'))

export const auth = {
    namespaced: true,
    state: {
        status: { loggedIn: token ? true : false },
        user: null,
    },
    actions: {
        profile({ commit, dispatch }) {
            AuthService.profile(token).then(
                user => {
                    commit('profileUpdate', user)
                    return Promise.resolve(user)
                },
                error => {
                    commit('logoutSuccess')
                    return Promise.reject(error)
                })
            dispatch('basic/auth', true, { root: true })
        },
        login({ commit, dispatch }, user) {
            return AuthService.login(user).then(
                response => {
                    dispatch('basic/auth', false, { root: true })
                    commit('loginSuccess', response)
                    Promise.resolve(response)
                    return dispatch('basic/auth', true, { root: true })
                },
                error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                })
        },
        register({ commit, dispatch }, user) {
            AuthService.register(user).then(
                response => {
                    dispatch('basic/auth', false, { root: true })
                    commit('registerSuccess', response)
                    Promise.resolve(response)
                    return dispatch('basic/auth', true, { root: true })
                },
                error => {
                    commit('registerFailure')
                    return Promise.reject(error)
                })
        },
        logout({ commit, dispatch }) {
            dispatch('basic/auth', false, { root: true })
            AuthService.logout()
            commit('logoutSuccess')
            return dispatch('basic/auth', true, { root: true })
        },
        updatePassword({ commit }, user) {
            return AuthService.updatePassword(token, user).then(
                response => {
                    commit('profileUpdate', response)
                    return Promise.resolve(response)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        },
        updateProfile({ commit }, user) {
            return AuthService.updateProfile(token, user).then(
                response => {
                    commit('profileUpdate', response)
                    return Promise.resolve(response)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        },
        verify({ commit }, user) {
            return AuthService.verify(token, user).then(
                response => {
                    commit('profileUpdate', response)
                    return Promise.resolve(response)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        }
    },
    mutations: {
        profileUpdate(state, user) {
            state.status.loggedIn = true
            state.user = user
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true
            state.user = user
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.user = null
        },
        registerSuccess(state, user) {
            state.status.loggedIn = true
            state.user = user
        },
        registerFailure(state) {
            state.status.loggedIn = false
            state.user = null
        },
        logoutSuccess(state) {
            state.status.loggedIn = false
            state.user = null
        }
    }
}
