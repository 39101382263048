import axios from 'axios'
import CONFIG from '../config/settings'
const API_URL = CONFIG.APIURL

class PaymentService {
    getAddress(token) {
        return axios
            .post(API_URL + 'payment/deposit', { id: token })
            .then(response => {
                return response.data
            })
    }

    getTransactions(token) {
        return axios
            .post(API_URL + 'payment/transactions', { id: token })
            .then(response => {
                return response.data
            })
    }

    sendWithdraw(token, params) {
        return axios
            .post(API_URL + 'payment/withdraw', {
                id: token,
                address: params.address,
                amount: params.amount
            })
            .then(response => {
                return response.data
            })
    }
}

export default new PaymentService();