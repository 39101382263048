export const data = {
    namespaced: true,
    state: {
        assets: null,
        active: { asset: 'BTC/USD' },
    },
    actions: {
        assets({ commit, dispatch }, data) {
            commit('assetsUpdate', data)
            dispatch('basic/data', true, { root: true })
            dispatch('basic/info', true, { root: true })
            dispatch('basic/order', true, { root: true })
        },
        change({ commit, dispatch }, data) {
            dispatch('basic/info', false, { root: true })
            dispatch('basic/chart', 0, { root: true })
            dispatch('basic/order', false, { root: true })
            commit('assetSet', data)
            dispatch('basic/info', true, { root: true })
            dispatch('basic/order', true, { root: true })
        }
    },
    mutations: {
        assetsUpdate(state, data) {
            state.assets = data
        },
        assetSet(state, data) {
            state.active.asset = data
        }
    }
}
