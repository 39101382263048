<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.$store.dispatch('auth/profile')
  },
  sockets: {
    connect() {
      console.log('socket connected')
    },
    disconnect() {
      console.log('socket disconnected')
    }
  }
}
</script>
